<template>
  <div
    :key="selectedAccommodationId"
    class="tw-flex-col tw-flex tw-w-full md:tw-max-w-screen-lg lg:tw-max-w-screen-xl tw-mr-auto tw-ml-auto tw-min-h-screen-minus-header-and-footer tw-px-4"
  >
    <LaunchpadLoader
      v-if="isLoadingRequiredData"
      class="tw-w-full tw-flex tw-justify-center tw-items-center tw-flex-1"
    />
    <div v-else-if="isRequiredDataError" class="tw-flex tw-justify-center tw-items-center tw-flex-1">
      <ErrorModal
        :allow-retry="false"
        :app-name="'Rate Connect'"
        :use-app-name="true"
        header-key="onboarding.appLaunchError.header"
        content-key="onboarding.appLaunchError.text"
        retry-key="onboarding.appLaunchError.retryButton"
      />
    </div>

    <router-view v-else />
  </div>
</template>

<script setup>
import { computed, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'

import LaunchpadLoader from '@/components/dashboard/common/LaunchpadLoader.vue'
import ErrorModal from '@/components/dashboard/ErrorModal.vue'
import { useCampaigns, useMarkets, usePartners, useStartUp } from '@/components/rateConnect/queries'
import { selectedAccommodationId } from '@/layouts/queries'
import { useSetup } from '@/components/rateConnect/setup/useSetup'

const { selectedPartner, resetSelectedPartner } = useSetup()

const router = useRouter()
const route = useRoute()
const store = useStore()

const language = computed(() => store.state.locale?.language)

const {
  isLoading: isStartUpLoading,
  isError: isStartUpError,
  hotelierData,
  currentCampaignType,
} = useStartUp(selectedAccommodationId)
const { isLoading: isPartnersLoading, isError: isPartnersError, partnersData } = usePartners(selectedAccommodationId)
const {
  isLoading: isCampaignsLoading,
  isError: isCampaignsError,
  currentPartner,
} = useCampaigns(selectedAccommodationId)
const { isInitialLoading: isMarketsLoading } = useMarkets(selectedAccommodationId, language, currentPartner)

const isLoadingRequiredData = computed(
  () => isMarketsLoading.value || isStartUpLoading.value || isPartnersLoading.value || isCampaignsLoading.value
)
const isRequiredDataError = computed(() => isStartUpError.value || isPartnersError.value || isCampaignsError.value)

const hasRateConnect = computed(() => hotelierData.value?.hasRateConnect)
const isEnabledForRateConnect = computed(() => hotelierData.value?.isEnabledForRateConnect)
const isEligibleForNetCPA = computed(() => hotelierData.value?.eligibleCampaignTypes?.includes('netCpa'))

const decideRoute = () => {
  if (isLoadingRequiredData.value) return

  const currentPath = route.name

  if (currentPath === 'rate-connect' && hasRateConnect.value === true) {
    // User is in rate-connect path and has rate connect campaign
    router.replace({ name: 'rate-connect-running-performance' })
    return
    // User is in rate-connect path and doesn't have rate connect campaign
  } else if (
    (currentPath === 'rate-connect' || currentPath.includes('rate-connect-running')) &&
    hasRateConnect.value === false
  ) {
    router.replace('/rate-connect/no-campaign')
    return
  }
  // User is in exactly rate-connect (no sub-paths) and has an existing campaign.
  else if (
    (currentPath === 'rate-connect-no-campaign' || currentPath === 'rate-connect-noteligible') &&
    hasRateConnect.value
  ) {
    router.replace({ name: 'rate-connect-running-performance' })
    return
  }
  // User is in exactly rate-connect/running (no sub-paths) and has an existing campaign. /running has no page itself.
  else if (currentPath === 'rate-connect-running' && hasRateConnect.value) {
    router.replace('/rate-connect/running/performance')
    return
  }
  // User is in exactly rate-connect/setup (no sub-paths) and has an existing campaign.
  else if (currentPath === 'rate-connect-setup' && hasRateConnect.value) {
    router.replace('/rate-connect/running/performance')
    return
  }
  // User is exactly in rate-connect/setup (no sub-paths) and doesn't have existing campaign.
  else if (currentPath === 'rate-connect-setup' && !hasRateConnect.value && selectedPartner.value === null) {
    router.replace('/rate-connect/no-campaign')
    return
  }
  // User is in exactly rate-connect/noteligible and has a valid enabled partner
  else if (
    currentPath === 'rate-connect-noteligible' &&
    isEnabledForRateConnect.value &&
    partnersData.value &&
    isEligibleForNetCPA.value
  ) {
    router.replace('/rate-connect/no-campaign')
    return
  }
  // User changed from one accommodation with CPC to another without it and he was previously in Bidding or Budget page.
  else if ((currentPath.includes('bidding') || currentPath.includes('budget')) && currentCampaignType.value !== 'cpc') {
    router.replace('/rate-connect/running/performance')
    return
  }
}

watch(
  route,
  () => {
    if (!isLoadingRequiredData.value) {
      decideRoute()
    }
  },
  { flush: 'pre', immediate: true, deep: true }
)

watch(selectedAccommodationId, newSelectedAccommodationId => {
  if (newSelectedAccommodationId && isLoadingRequiredData.value) {
    resetSelectedPartner()
    decideRoute()
  }
})

watch(isLoadingRequiredData, () => {
  decideRoute()
})
</script>

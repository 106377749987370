<template>
  <CustomModal
    class="export-data-modal"
    :is-modal-open="isOpen"
    @toggleModal="emit('closeModal')"
    @onClickAway="emit('closeModal')"
    :overlay="'dark'"
    :with-header="true"
    :with-footer="true"
    custom-classes="tw-h-screen md:tw-h-auto tw-w-[100vw] md:tw-max-w-[636px] md:tw-w-[636px] md:tw-rounded-md tw-rounded-none"
  >
    <template #modalHeader
      ><p class="tw-text-xl">{{ $t('rc_opportunities_top_position_info_header') }}</p></template
    >

    <div class="tw-flex tw-flex-col">
      <div class="tw-mx-auto tw-mb-6">
        <img
          :src="getImage('web')"
          :alt="$t('rc_opportunities_top_position_info_header')"
          class="tw-max-h-[320px] tw-hidden md:tw-flex"
        />
        <img
          :src="getImage('mobile')"
          :alt="$t('rc_opportunities_top_position_info_header')"
          class="tw-max-h-[320px] md:tw-hidden tw-flex"
        />
      </div>
      <p class="tw-pb-6" v-html="$t('rc_opportunities_top_position_info')" />
    </div>

    <template #modalFooter>
      <div class="tw-flex tw-w-full tw-justify-end">
        <MainButton size="medium" @click="emit('closeModal')">{{ $t('rc_opportunities_cta_close') }}</MainButton>
      </div>
    </template>
  </CustomModal>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

import CustomModal from '@/components/base/CustomModal.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'

const store = useStore()
const emit = defineEmits(['closeModal'])
defineProps({ isOpen: Boolean })

const language = computed(() => store.state.locale.language)
const langCode = computed(() => language.value.split('-')[1].toLowerCase())

const LearnMore_Web_US = new URL('/src/assets/img/opportunity-area/learn-more-web-us.png', import.meta.url)
const LearnMore_Web_DE = new URL('/src/assets/img/opportunity-area/learn-more-web-de.png', import.meta.url)
const LearnMore_Web_BR = new URL('/src/assets/img/opportunity-area/learn-more-web-br.png', import.meta.url)
const LearnMore_Web_EL = new URL('/src/assets/img/opportunity-area/learn-more-web-el.png', import.meta.url)
const LearnMore_Web_ES = new URL('/src/assets/img/opportunity-area/learn-more-web-es.png', import.meta.url)
const LearnMore_Web_FR = new URL('/src/assets/img/opportunity-area/learn-more-web-fr.png', import.meta.url)
const LearnMore_Web_MX = new URL('/src/assets/img/opportunity-area/learn-more-web-mx.png', import.meta.url)
const LearnMore_Web_IT = new URL('/src/assets/img/opportunity-area/learn-more-web-it.png', import.meta.url)
const LearnMore_Web_JA = new URL('/src/assets/img/opportunity-area/learn-more-web-ja.png', import.meta.url)
const LearnMore_Web_NL = new URL('/src/assets/img/opportunity-area/learn-more-web-nl.png', import.meta.url)
const LearnMore_Web_PL = new URL('/src/assets/img/opportunity-area/learn-more-web-pl.png', import.meta.url)
const LearnMore_Web_PT = new URL('/src/assets/img/opportunity-area/learn-more-web-pt.png', import.meta.url)
const LearnMore_Web_RU = new URL('/src/assets/img/opportunity-area/learn-more-web-ru.png', import.meta.url)
const LearnMore_Web_TR = new URL('/src/assets/img/opportunity-area/learn-more-web-tr.png', import.meta.url)
const LearnMore_Web_UK = new URL('/src/assets/img/opportunity-area/learn-more-web-uk.png', import.meta.url)

const LearnMore_mobile_US = new URL('/src/assets/img/opportunity-area/learn-more-mobile-us.png', import.meta.url)
const LearnMore_mobile_DE = new URL('/src/assets/img/opportunity-area/learn-more-mobile-de.png', import.meta.url)
const LearnMore_mobile_BR = new URL('/src/assets/img/opportunity-area/learn-more-mobile-br.png', import.meta.url)
const LearnMore_mobile_EL = new URL('/src/assets/img/opportunity-area/learn-more-mobile-el.png', import.meta.url)
const LearnMore_mobile_ES = new URL('/src/assets/img/opportunity-area/learn-more-mobile-es.png', import.meta.url)
const LearnMore_mobile_FR = new URL('/src/assets/img/opportunity-area/learn-more-mobile-fr.png', import.meta.url)
const LearnMore_mobile_MX = new URL('/src/assets/img/opportunity-area/learn-more-mobile-mx.png', import.meta.url)
const LearnMore_mobile_IT = new URL('/src/assets/img/opportunity-area/learn-more-mobile-it.png', import.meta.url)
const LearnMore_mobile_JA = new URL('/src/assets/img/opportunity-area/learn-more-mobile-ja.png', import.meta.url)
const LearnMore_mobile_NL = new URL('/src/assets/img/opportunity-area/learn-more-mobile-nl.png', import.meta.url)
const LearnMore_mobile_PL = new URL('/src/assets/img/opportunity-area/learn-more-mobile-pl.png', import.meta.url)
const LearnMore_mobile_PT = new URL('/src/assets/img/opportunity-area/learn-more-mobile-pt.png', import.meta.url)
const LearnMore_mobile_RU = new URL('/src/assets/img/opportunity-area/learn-more-mobile-ru.png', import.meta.url)
const LearnMore_mobile_TR = new URL('/src/assets/img/opportunity-area/learn-more-mobile-tr.png', import.meta.url)
const LearnMore_mobile_UK = new URL('/src/assets/img/opportunity-area/learn-more-mobile-uk.png', import.meta.url)

const getImage = type => {
  let image = type === 'web' ? LearnMore_Web_US : LearnMore_mobile_US
  switch (langCode.value) {
    case 'de':
      image = type === 'web' ? LearnMore_Web_DE : LearnMore_mobile_DE
      break
    case 'gr':
      image = type === 'web' ? LearnMore_Web_EL : LearnMore_mobile_EL
      break
    case 'us':
      image = type === 'web' ? LearnMore_Web_US : LearnMore_mobile_US
      break
    case 'gb':
      image = type === 'web' ? LearnMore_Web_UK : LearnMore_mobile_UK
      break
    case 'es':
      image = type === 'web' ? LearnMore_Web_ES : LearnMore_mobile_ES
      break
    case 'mx':
      image = type === 'web' ? LearnMore_Web_MX : LearnMore_mobile_MX
      break
    case 'fr':
      image = type === 'web' ? LearnMore_Web_FR : LearnMore_mobile_FR
      break
    case 'it':
      image = type === 'web' ? LearnMore_Web_IT : LearnMore_mobile_IT
      break
    case 'pl':
      image = type === 'web' ? LearnMore_Web_PL : LearnMore_mobile_PL
      break
    case 'nl':
      image = type === 'web' ? LearnMore_Web_NL : LearnMore_mobile_NL
      break
    case 'br':
      image = type === 'web' ? LearnMore_Web_BR : LearnMore_mobile_BR
      break
    case 'jp':
      image = type === 'web' ? LearnMore_Web_JA : LearnMore_mobile_JA
      break
    case 'pt':
      image = type === 'web' ? LearnMore_Web_PT : LearnMore_mobile_PT
      break
    case 'ru':
      image = type === 'web' ? LearnMore_Web_RU : LearnMore_mobile_RU
      break
    case 'tr':
      image = type === 'web' ? LearnMore_Web_TR : LearnMore_mobile_TR
      break
    default:
      image = type === 'web' ? LearnMore_Web_US : LearnMore_mobile_US
      break
  }
  return image
}
</script>

<template>
  <div class="sm:tw-mx-4 lg:tw-mx-0">
    <div v-if="initialDataLoading" class="tw-rounded-xl tw-animate-pulse tw-bg-gray-300 tw-h-[658px]"></div>
    <div v-else-if="requiredDataHasErrors">
      <p class="tw-text-center tw-text-xl">{{ $t('onboarding.appLaunchError.header') }}</p>
    </div>

    <OpportunityAreaWrapper v-else :impressions="impressions" />
    <FullScreenLoader v-show="requiredDataIsBeingRefetched" />
  </div>
</template>
<script setup>
import { computed } from 'vue'

import {
  useStartUp,
  useExpectedTopShare,
  useCampaigns,
  useCompetitivenessStats,
} from '@/components/rateConnect/queries'
import { selectedAccommodationId } from '@/layouts/queries'

import OpportunityAreaWrapper from '@/components/rateConnect/opportunityArea/OpportunityAreaWrapper.vue'

const {
  isFetching: isStartUpFetching,
  isLoading: isStartUpLoading,
  isError: isStartUpError,
} = useStartUp(selectedAccommodationId)
const {
  competitivenessStats,
  isLoading: isCompetitivenessStatsLoading,
  isFetching: isCompetitivenessStatsFetching,
  isError: isCompetitivenessStatsError,
} = useCompetitivenessStats(selectedAccommodationId)

const { currentCommissionLevel } = useCampaigns(selectedAccommodationId)
const { isFetching: isExpectedTopShareFetching } = useExpectedTopShare(currentCommissionLevel)

const initialDataLoading = computed(() => isStartUpLoading.value || isCompetitivenessStatsLoading.value)
const requiredDataHasErrors = computed(() => isStartUpError.value || isCompetitivenessStatsError.value)
const requiredDataIsBeingRefetched = computed(
  () =>
    !initialDataLoading.value &&
    isStartUpFetching.value &&
    isExpectedTopShareFetching.value &&
    isCompetitivenessStatsFetching.value
)

const impressions = computed(() => competitivenessStats.value?.hotelImpressions)
</script>

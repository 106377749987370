<template>
  <figure class="highcharts-figure">
    <div :id="containerName"></div>
  </figure>
</template>

<script setup>
import { nextTick, watch } from 'vue'
import Highcharts from 'highcharts'
import * as Sentry from '@sentry/vue'

const props = defineProps({
  series: {
    type: Array,
    default() {
      return []
    },
  },
  isWaitingMode: {
    type: Boolean,
    required: true,
    default: true,
  },
  isMobile: Boolean,
  language: String,
})

const containerName = 'competitiveness-rate-graph-container'

const getText = () => {
  const totalNumber = Intl.NumberFormat(props.language, { style: 'percent', maximumFractionDigits: 0 }).format(
    100 / 100
  )

  return `<div class="tw-text-xl sm:tw-text-3xl md:tw-text-2xl landing:tw-text-3xl tw-text-black tw-font-bold">${totalNumber}</div>`
}

const getFormattedPercentage = item => {
  let newNumber = Intl.NumberFormat(props.language, { style: 'percent' }).format(item / 100)
  if (props.language === 'de-DE' || props.language === 'es-ES' || props.language === 'fr-FE') {
    newNumber = newNumber.slice(0, -1) + ' %'
  }

  return newNumber
}

const drawChart = (series, isMobile) => {
  if (!series) return
  try {
    // without nextTick, highcharts may use the container before it exists in the DOM, it is important!
    nextTick(() => {
      // if for some reason the container is not yet mounted, go back.
      if (!document.getElementById(containerName)) return
      Highcharts.chart(containerName, {
        chart: {
          type: 'pie',
          custom: {},
          events: {
            render() {
              const chart = this,
                series = chart.series[0]
              let customLabel = chart.options.chart?.custom?.label

              if (!customLabel) {
                customLabel = chart.options.chart.custom.label = chart.renderer
                  .label(`<strong>${getText()}</strong>`)
                  .css({
                    textAnchor: 'middle',
                  })
                  .add()
              }

              const x = series.center[0] + chart.plotLeft,
                y = series.center[1] + chart.plotTop - customLabel.attr('height') / 2

              customLabel.attr({
                x,
                y,
              })
              customLabel.css({
                fontSize: `${series.center[2] / 12}px`,
              })
            },
          },
        },
        title: null,
        accessibility: {
          point: {
            valueSuffix: '%',
          },
        },
        credits: {
          enabled: false,
        },
        series: [
          {
            colorByPoint: true,
            data: series,
          },
        ],

        plotOptions: {
          pie: {
            innerSize: '70%',
            showInLegend: true,
          },
          series: {
            enableMouseTracking: false,
            states: {
              hover: {
                enabled: false,
              },
            },
            allowPointSelect: false,
            borderRadius: 0,
            borderWidth: 0,
            dataLabels: [
              {
                enabled: isMobile ? false : true,
                distance: 20,
                formatter: function () {
                  return Math.round(this.point.percentage) > 3
                    ? `<p class="tw-text-[8px] sm:tw-text-[10px] tw-font-normal tw-text-wrap">${this.point.name}</p>`
                    : `<p class="tw-text-[8px] sm:tw-text-[10px] tw-font-normal tw-text-wrap">${getFormattedPercentage(this.point.percentage)} - ${this.point.name}</p>`
                },
                alignTo: 'connector',
                useHTML: true,
              },
              {
                enabled: isMobile ? false : true,
                crop: false,
                distance: '-14%',
                useHTML: true,
                formatter: function () {
                  return Math.round(this.point.percentage) > 3
                    ? `<span class="sm:tw-text-[10px] landing:tw-text-sm">${getFormattedPercentage(this.point.percentage)}</span>`
                    : ''
                },
              },
            ],
          },
        },
        legend: {
          itemStyle: {
            cursor: 'default',
          },
          itemHoverStyle: {
            color: '#333333',
          },
          enabled: isMobile ? true : false,
          events: {
            itemClick: function () {
              return false
            },
          },
          labelFormatter: function () {
            return `${getFormattedPercentage(this.y)} - ${this.name}`
          },
          itemDistance: 10,
        },
        tooltip: {
          enabled: false,
        },
      })
    })
  } catch (error) {
    Sentry.captureException(error)
  }
}

watch(
  () => props,
  newProps => {
    if (newProps.series?.length > 0) {
      drawChart(newProps.series, newProps.isMobile)
    }
  },
  { deep: true, immediate: true }
)
</script>
<style scoped>
.highcharts-figure {
  min-width: 300px;
  max-width: 660px;
  margin: 1em auto;
}
</style>

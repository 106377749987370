<template>
  <div class="tw-flex tw-flex-col">
    <OpportunityNoData v-if="impressions < 100" />
    <BaseCard v-else noPadding class="tw-bg-white tw-rounded-lg tw-p-6">
      <OpportunityAreaCommissionLevel />
      <OpportunityAreaRateCompetitiveness :isPlus="!hasPlus" />
      <OpportunityAreaPlus v-if="!hasPlus" />
    </BaseCard>
  </div>
</template>
<script setup>
import { computed } from 'vue'

import BaseCard from '@/components/dashboard/BaseCard.vue'
import OpportunityNoData from '@/components/rateConnect/opportunityArea/OpportunityNoData.vue'
import OpportunityAreaPlus from '@/components/rateConnect/opportunityArea/OpportunityAreaPlus.vue'
import OpportunityAreaCommissionLevel from '@/components/rateConnect/opportunityArea/commissionLevel/OpportunityAreaCommissionLevel.vue'
import OpportunityAreaRateCompetitiveness from '@/components/rateConnect/opportunityArea/rateCompetitiveness/OpportunityAreaRateCompetitiveness.vue'

import { selectedAccommodationId } from '@/layouts/queries'
import { useStudioPlusCampaign } from '@/components/payment/queries'

const { isExpiredSubscription, isPlus, isPro } = useStudioPlusCampaign(selectedAccommodationId)
const hasPlus = computed(() => {
  return (isPlus.value || isPro.value) && !isExpiredSubscription.value
})
defineProps({
  impressions: Number,
})
</script>

<template>
  <div>
    <h1 class="tw-text-black tw-font-bold tw-text-2xl tw-mb-3">{{ $t('rc_opportunities_plus_promo_header') }}</h1>
    <p>{{ $t('rc_opportunities_plus_promo_subtext') }}</p>
    <div class="tw-flex tw-flex-col md:tw-flex-row tw-mt-8 tw-gap-x-8">
      <div class="md:tw-w-1/2">
        <div
          v-for="item in products"
          :key="item.id"
          class="tw-mb-2 tw-p-4 tw-pr-0 tw-flex tw-flex-col tw-rounded-xl tw-gap-x-4 tw-w-full"
        >
          <div class="tw-flex tw-justify-between tw-items-center tw-w-full">
            <div class="tw-flex tw-items-start md:tw-items-center tw-gap-4">
              <div class="tw-min-w-12 tw-mt-1 md:tw-mt-0">
                <img :src="item.icon" />
              </div>
              <div class="tw-w-auto">
                <p class="tw-font-bold tw-text-xl tw-mb-1">
                  {{ $t(item.title) }}
                </p>
                <p class="tw-text-sm tw-text-gray-700" v-html="$t(item.subtitle)" />
              </div>
            </div>

            <BaseIcon width="24" height="24" iconName="chevron-right">
              <ChevronRight />
            </BaseIcon>
          </div>
        </div>
      </div>
      <div class="md:tw-w-1/2 tw-mt-4 md:tw-mt-0 tw-flex tw-flex-col tw-bg-gray-100 tw-rounded-2xl tw-p-6">
        <div class="tw-mb-8 tw-h-full">
          <h2 class="tw-text-xl tw-font-bold tw-mb-2">{{ $t('rc_opportunities_plus_benefit_headline') }}</h2>
          <ul class="tw-list-disc tw-ml-6">
            <li v-for="item in benefits" :key="item">{{ $t(item) }}</li>
          </ul>
        </div>

        <div>
          <div class="tw-flex tw-flex-col tw-items-start">
            <div class="tw-bg-red-200 tw-text-red-800 tw-py-1 tw-px-2 tw-rounded tw-text-sm tw-w-auto">
              <i18n-t keypath="rc_opporunities_plus_price">
                <template #SubscriptionPrice
                  ><strong v-if="!isPriceLoading">{{
                    Intl.NumberFormat(locale, {
                      style: 'currency',
                      currency,
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 0,
                    }).format(price / 100)
                  }}</strong>
                  <RCInlineLoader v-else color="#B4183A" />
                </template>
              </i18n-t>
            </div>
            <small class="tw-text-gray-700 tw-text-xs tw-mt-1">{{
              `*${$t('business_studio_plus_tax_disclaimer')}`
            }}</small>
          </div>
          <MainButton class="tw-mt-4 tw-w-full md:tw-w-auto" @click="onPlusRedirectClick">{{
            $t('rc_opportunities_plus_cta')
          }}</MainButton>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useStudioPlusPrice } from '@/components/payment/queries'
import { computed } from 'vue'
import { useStore } from 'vuex'
import { selectedAccommodationId } from '@/layouts/queries'
import appAnalytics from '@/utils/tracking'

import MainButton from '@/components/base/buttons/MainButton.vue'
import { useRouter } from 'vue-router'

const HotelConnect = new URL('/src/assets/img/studio-plus/icons/HotelConnect.svg', import.meta.url)
const RateInsights = new URL('/src/assets/img/studio-plus/icons/RateInsights.svg', import.meta.url)
const TravelerTrends = new URL('/src/assets/img/studio-plus/icons/TravelerTrends.svg', import.meta.url)

const store = useStore()
const router = useRouter()
const currency = computed(() => store.state.currency)
const { studioPlusPrice, isLoading, isFetching } = useStudioPlusPrice({ selectedAccommodationId, currency })
const isPriceLoading = computed(() => isLoading.value || isFetching.value)
const price = computed(() => studioPlusPrice.value?.baseSubscriptionMonthlyPrice ?? 0)

const onPlusRedirectClick = () => {
  appAnalytics.track(appAnalytics.events.RC_OPPORTUNITY_PLUS_REDIRECTION, {
    accommodationId: selectedAccommodationId.value,
  })
  router.push('/studio-plus/compare')
}
const benefits = [
  'rc_opportunities_plus_benefit_1',
  'rc_opportunities_plus_benefit_2',
  'rc_opportunities_plus_benefit_3',
]
const products = [
  {
    title: 'rc_opportunities_plus_hotel_connect',
    id: 0,
    subtitle: 'rc_opportunities_plus_hc_text',
    icon: HotelConnect,
  },
  {
    title: 'rc_opportunities_plus_rate_insights',
    id: 1,
    subtitle: 'rc_opportunities_plus_ri_text',
    icon: RateInsights,
  },
  {
    title: 'rc_opportunities_plus_visitors_profile',
    id: 2,
    subtitle: 'rc_opportunities_plus_vp_text',
    icon: TravelerTrends,
  },
]
</script>

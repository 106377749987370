<template>
  <h4 class="tw-font-bold tw-text-xl tw-mb-4">
    {{ $t('subscriptions.details.transactionHistory.heading') }}
  </h4>
  <div v-if="isFetching">
    <div
      v-for="item in [0, 1, 2]"
      :key="item"
      class="tw-animate-pulse tw-bg-gray-300 tw-rounded tw-w-full tw-w-full tw-h-6 tw-my-3"
      aria-hidden="true"
    ></div>
  </div>
  <div v-else-if="isError" class="tw-text-red-700" v-html="$t('errors.wrong_data_from_app')" />

  <div v-else>
    <ul>
      <li
        v-for="(transaction, i) in subscriptionHistory"
        :key="i"
        class="tw-flex tw-items-center tw-w-full tw-my-2 first:tw-mt-0 last:tw-mb-0 hover:tw-bg-gray-100 tw-rounded-lg tw-p-2"
      >
        <div class="tw-inline-block tw-h-[5px] tw-w-[5px] tw-bg-gray-800 tw-rounded-full tw-mx-2" />
        <button class="tw-w-full tw-text-left" @click="downloadPdf(transaction.invoiceId)">
          {{
            $t('subscriptions.details.transactionHistory.invoice', { date: getInvoiceDate(transaction.invoiceDate) })
          }}
        </button>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

import { useSubscriptionHistory } from '@/components/settings/subscriptions/queries'
import PremiumService from '@/services/PremiumService'

const props = defineProps({
  productType: {
    type: String,
    default: '',
    required: true,
  },
  accommodationId: {
    type: Number,
    default: 0,
    required: true,
  },
})

const store = useStore()
const { subscriptionHistory, isFetching, isError } = useSubscriptionHistory(props.accommodationId, props.productType)

const language = computed(() => store.state?.locale?.language ?? 'en-US')

const getInvoiceDate = date => {
  return new Date(date).toLocaleDateString(language.value, {
    year: 'numeric',
    month: 'long',
    day: '2-digit',
  })
}

const downloadPdf = async invoiceId => {
  const response = await PremiumService.getInvoice(invoiceId)
  if (response.data) {
    const fileURL = window.URL.createObjectURL(response.data)
    const fileLink = document.createElement('a')
    fileLink.href = fileURL
    fileLink.setAttribute('download', `${invoiceId}.pdf`)
    document.body.appendChild(fileLink)
    fileLink.click()
  }
}
</script>
<style scoped>
.dot {
  height: 10px;
  width: 10px;
  display: block;
  background-color: theme('colors.gray.800');
}
</style>

<template>
  <label
    :for="cpa"
    class="hover:tw-cursor-pointer tw-border-2 hover:tw-bg-blue-100 tw-flex tw-flex-col sm:tw-flex-row md:tw-flex-col lg:tw-flex-row tw-w-full shadow tw-rounded-xl tw-py-3 tw-pl-6 tw-pr-3 tw-mb-0 tw-min-h-[90px] tw-items-center tw-relative"
    :class="
      isSelected
        ? ' tw-border-blue-700 tw-bg-blue-100 tw-justify-between tw-gap-x-4'
        : 'tw-border-transparent tw-bg-white'
    "
  >
    <div
      v-if="isCurrent"
      class="tw-bg-blue-700 tw-absolute tw-bottom-[-2px] tw-right-[-2px] tw-text-white tw-font-bold tw-py-1 tw-px-2 tw-text-xs tw-rounded-tl-xl tw-rounded-br-xl"
    >
      {{ $t('rc_opportunities_current_commission') }}
    </div>
    <div
      class="tw-flex tw-items-center tw-w-full"
      :class="{ 'tw-py-4 sm:tw-py-0 md:tw-py-4 xl:tw-py-0': cpa === currentCommissionLevel }"
    >
      <input
        type="radio"
        :value="cpa"
        :id="cpa"
        :checked="isSelected"
        @change="onChange(cpa)"
        class="tw-cursor-pointer tw-mr-4 tw-mb-1"
      />
      <div class="tw-w-full">
        <p class="tw-text-[20px] tw-leading-[25px] tw-font-bold tw-pb-1">
          {{ $t(commissionLabel) }}
        </p>
        <div
          class="tw-flex xl:tw-flex-row tw-flex-col sm:tw-flex-row md:tw-flex-col tw-items-start md:tw-items-start sm:tw-items-center xl:tw-items-center tw-gap-x-2 tw-text-[12px] tw-leading-[15px] sm:tw-text-[14px] sm:tw-leading-[20px] md:tw-text-[12px] md:tw-leading-[15px] xl:tw-text-[14px] xl:tw-leading-[20px]"
          v-if="cpa !== currentCommissionLevel"
        >
          <p>
            {{
              isVariationNegative
                ? $t('rc_opportunities_commission_decrease')
                : $t('rc_opportunities_commission_increase')
            }}
          </p>
          <div
            class="tw-text-[14px] tw-leading-[14px] sm:tw-text-[16px] sm:tw-leading-[16px] md:tw-text-[14px] md:tw-leading-[14px] xl:tw-text-[16px] xl:tw-leading-[16px] tw-gap-x-1 tw-font-bold tw-bg-white tw-flex tw-justify-centre tw-items-center tw-border tw-border-gray-300 tw-rounded-md tw-py-1 tw-px-2 tw-mt-1 sm:tw-mt-0 md:tw-mt-1 xl:tw-mt-0"
          >
            <BaseIcon :height="11" :width="11" :icon-name="isVariationNegative ? 'arrow-down-right' : 'arrow-up-right'">
              <ArrowDownRight v-if="isVariationNegative" />
              <ArrowUpRight v-else />
            </BaseIcon>

            {{ getFormattedPercentage() }}
          </div>
        </div>
        <div
          v-if="!isCurrent && isSelected"
          class="tw-flex tw-mt-3 sm:tw-hidden sm:tw-mt-0 lg:tw-hidden lg:tw-mt-0 md:tw-flex md:tw-mt-3"
        >
          <MainButton @click="onSelect" class="tw-w-full sm:tw-w-auto md:tw-w-full lg:tw-w-auto">{{
            $t('rc_opportunities_commission_cta')
          }}</MainButton>
        </div>
      </div>
    </div>
    <div
      v-if="!isCurrent && isSelected"
      class="tw-hidden tw-mt-3 sm:tw-flex sm:tw-mt-0 lg:tw-flex lg:tw-mt-0 md:tw-hidden md:tw-mt-3"
    >
      <MainButton @click="onSelect" class="tw-w-full sm:tw-w-auto md:tw-w-full lg:tw-w-auto">
        {{ $t('rc_opportunities_commission_cta') }}
      </MainButton>
    </div>
  </label>
</template>
<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

import MainButton from '@/components/base/buttons/MainButton.vue'
import ArrowUpRight from '@/components/icons/ArrowUpRight.vue'
import ArrowDownRight from '@/components/icons/ArrowDownRight.vue'
import BaseIcon from '@/components/BaseIcon.vue'

const store = useStore()
const props = defineProps({
  cpa: Number,
  variation: Number,
  currentCommissionLevel: String,
  selectedCommissionLevel: String,
  loading: Boolean,
})

const emit = defineEmits(['onChange', 'onSelect'])
const onChange = newCpa => emit('onChange', newCpa)
const onSelect = () => emit('onSelect')
const language = computed(() => store.state.locale.language)
const commissionLabel = computed(() => {
  if (props.cpa === 10) return 'rc_opportunities_commission_10'
  if (props.cpa === 12) return 'rc_opportunities_commission_12'
  if (props.cpa === 15) return 'rc_opportunities_commission_15'
  if (props.cpa === 18) return 'rc_opportunities_commission_18'
  if (props.cpa === 20) return 'rc_opportunities_commission_20'
  if (props.cpa === 25) return 'rc_opportunities_commission_25'
  else return ''
})

const isSelected = computed(() => props.cpa === props.selectedCommissionLevel)
const isCurrent = computed(() => props.cpa === props.currentCommissionLevel)
const isVariationNegative = computed(() => props.variation < 0)
const getFormattedPercentage = () => {
  let newVariation = Intl.NumberFormat(language.value, { style: 'percent' }).format(props.variation / 100)
  if (language.value === 'de-DE' || language.value === 'es-ES' || language.value === 'fr-FE') {
    newVariation = newVariation.slice(0, -1) + ' %'
  }

  return newVariation
}
</script>
<style scoped>
.shadow {
  box-shadow: 0px 4px 16px 0px #00000026;
}
</style>

<template>
  <div class="tw-bg-gray-100 tw-flex tw-flex-row tw-p-4 tw-rounded-2xl tw-justify-between tw-mb-4">
    <div class="tw-flex tw-items-center">
      <h3 class="tw-font-bold tw-mr-1">{{ $t(label) }}</h3>
      <UiTooltip :label="$t(tooltip)" tooltip-position="top" theme="customdarkwithpadding">
        <BaseIcon
          icon-name="information-outline"
          :height="16"
          :width="16"
          class="tw-fill-none tw-stroke-gray-500 tw-h-[19px] tw-w-[19px]"
        >
          <InformationOutline />
        </BaseIcon>
      </UiTooltip>
    </div>
    <p class="tw-text-[24px] tw-leading-[30px] sm:tw-text-3.5xl">
      {{ Intl.NumberFormat(language, { style: 'percent', minimumFractionDigits: 0 }).format(value / 100) }}
    </p>
  </div>
</template>
<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

import UiTooltip from '@/components/dashboard/ui/UiTooltip.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import InformationOutline from '@/components/icons/InformationOutline.vue'

const store = useStore()
defineProps({
  label: String,
  tooltip: String,
  value: Number,
})

const language = computed(() => store.state.locale.language)
</script>

<template>
  <div>
    <CompetitivenessRateGraph
      class="tw-h-auto md:tw-h-[450px] tw-mb-8 md:tw-mb-0"
      :series="series"
      :language="language"
      :isMobile="isMobile"
    />
  </div>
</template>
<script setup>
import { nextTick, onMounted, onUnmounted, ref } from 'vue'
import { useStore } from 'vuex'

import CompetitivenessRateGraph from '@/components/rateConnect/opportunityArea/rateCompetitiveness/CompetitivenessRateGraph.vue'
import { computed } from 'vue'

const store = useStore()

const isMobile = ref(false)
const language = computed(() => store.state.locale.language)
onMounted(() => {
  nextTick(() => {
    window.addEventListener('resize', onResize)
  })
})

onUnmounted(() => {
  window.removeEventListener('resize', onResize)
})

const onResize = event => {
  isMobile.value = event.target.innerWidth > 500 ? false : true
}
defineProps({
  series: Object,
})
</script>

<template>
  <div :id="containerName"></div>
</template>

<script setup>
import { nextTick, watch } from 'vue'
import Highcharts from 'highcharts'
import * as Sentry from '@sentry/vue'

const props = defineProps({
  colors: {
    type: Array,
    default() {
      return []
    },
  },
  series: {
    type: Array,
    default() {
      return []
    },
  },

  language: String,
  categories: {
    type: Array,
    default() {
      return []
    },
  },
})

const containerName = 'commission-levels-graph-container'

const emit = defineEmits(['selectLevel'])
const selectLevel = level => emit('selectLevel', level)

const drawChart = (series, categories, colors) => {
  if (!series || !categories) return
  try {
    // without nextTick, highcharts may use the container before it exists in the DOM, it is important!
    nextTick(() => {
      // if for some reason the container is not yet mounted, go back.
      if (!document.getElementById(containerName)) return
      Highcharts.chart(containerName, {
        title: null,
        chart: {
          animation: false,
          alignTicks: true,
          type: 'column',
          plotBorderWidth: 0,
          style: {
            fontFamily: `SourceSansPro, Sans-Serif, "Sans Serif"`,
          },
        },
        credits: {
          enabled: false,
        },
        series: series,
        xAxis: {
          categories: categories,
          tickLength: 0,
          gridLineWidth: 0,
          labels: {
            useHTML: true,
            formatter: function () {
              return `<span class="tw-text-sm sm:tw-text-base tw-text-black ">${this.value}</span>`
            },
          },
        },
        yAxis: {
          title: null,
          allowDecimals: true,
          gridLineColor: '#D9D8D6',
          gridLineDashStyle: 'Dash',
          tickLength: 0,
          gridLineWidth: 1,
          min: 0,
          max: categories.length === 6 ? 3 : 2.5,
          labels: {
            formatter: function () {
              return this.value + 'x'
            },
            style: {
              fontSize: '14',
            },
          },
          stackLabels: {
            style: {
              color: '#005FA3',
              fontSize: '24',
              textOutline: 0,
            },

            enabled: false,
            y: 0,
          },
        },
        plotOptions: {
          column: {
            stacking: 'normal',
            maxPointWidth: '90',
            borderRadius: 4,
          },
          series: {
            cursor: 'pointer',
            point: {
              events: {
                click: function () {
                  const category = this.category.replace('%', '')
                  selectLevel(category)
                },
              },
            },
            states: {
              hover: {
                brightness: 0.1,
                enabled: true,
              },
            },
            colorByPoint: true,
            colors: colors,
          },
        },
        legend: {
          enabled: false,
        },
        tooltip: {
          enabled: false,
        },
      })
    })
  } catch (error) {
    Sentry.captureException(error)
  }
}

watch(
  () => props,
  newProps => {
    if (newProps.series?.length > 0) {
      drawChart(newProps.series, newProps.categories, newProps.colors)
    }
  },
  { deep: true, immediate: true }
)
</script>

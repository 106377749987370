<template>
  <div class="tw-pb-6 tw-mb-6" :class="{ 'tw-border-b tw-border-gray-300': isPlus }">
    <h1 class="tw-text-black tw-font-bold tw-text-2xl tw-mb-3">
      {{ $t('rc_opportunities_competitiveness_header') }}
    </h1>
    <p>
      {{ $t('rc_opportunities_competitiveness_subtext') }}
    </p>
    <div class="tw-flex tw-flex-col md:tw-flex-row tw-mt-8 tw-gap-x-8">
      <div class="tw-w-full md:tw-w-1/2 tw-my-auto">
        <CompetitivenessRateGraphContainer :series="series" />
      </div>
      <div class="tw-w-full md:tw-w-1/2 md:tw-mb-0">
        <CompetitivenessStatsItem
          v-if="Math.round(getUnavailabilityRateValue) < 100"
          label="rc_opportunities_competitive_rate"
          :value="competitivenessStats.beatShare"
          tooltip="rc_opportunities_competitive_rate_tooltip"
        />
        <CompetitivenessStatsItem
          v-if="Math.round(getUnavailabilityRateValue) < 100"
          label="rc_opportunities_rate_parity"
          :value="competitivenessStats.meetShare"
          tooltip="rc_opportunities_parity_tooltip"
        />
        <CompetitivenessStatsItem
          v-if="Math.round(getUnavailabilityRateValue) < 100"
          label="rc_opportunities_non_competitive_rate"
          :value="competitivenessStats.loseShare"
          tooltip="rc_opportunities_non_competitive_tooltip"
        />
        <UnavailabilityItem
          label="rc_opportunities_rate_unavailability"
          :value="getUnavailabilityRateValue"
          tooltip="rc_opportunities_unavailability_tooltip"
          :benchmark="competitivenessStats.benchmark"
          :partnerName="currentRCPartner?.partnerName"
        />

        <p class="tw-text-xs tw-mt-3" v-if="days > 1">
          {{ '*' }}{{ $t('rc_opportunities_data_update', { days: days }) }}
        </p>
        <p v-else>{{ '*' }}{{ $t('rc_opportunities_data_update_24hours') }}</p>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'

import CompetitivenessStatsItem from '@/components/rateConnect/opportunityArea/rateCompetitiveness/CompetitivenessStatsItem.vue'
import UnavailabilityItem from '@/components/rateConnect/opportunityArea/rateCompetitiveness/UnavailabilityItem.vue'
import CompetitivenessRateGraphContainer from '@/components/rateConnect/opportunityArea/rateCompetitiveness/CompetitivenessRateGraphContainer.vue'

import { useCampaigns, useCompetitivenessStats, usePartners } from '@/components/rateConnect/queries'
import { selectedAccommodationId } from '@/layouts/queries'

defineProps({
  isPlus: Boolean,
})
const { t } = useI18n()
const { competitivenessStats } = useCompetitivenessStats(selectedAccommodationId)
const { currentPartner } = useCampaigns(selectedAccommodationId)
const { partnersData } = usePartners(selectedAccommodationId)
const currentRCPartner = computed(() => {
  return partnersData.value?.find(item => {
    return item.partnerId === currentPartner.value
  })
})

const days = computed(() => {
  const startDate = competitivenessStats.value?.referencePeriod?.startDate
  const endDate = competitivenessStats.value?.referencePeriod?.endDate
  const start = new Date(startDate)
  const end = new Date(endDate)

  const differenceInTime = end - start
  const differenceInDays = differenceInTime / (1000 * 60 * 60 * 24)

  return Math.abs(Math.round(differenceInDays))
})

const getUnavailabilityRateValue = computed(() => {
  const sum =
    Math.round(competitivenessStats.value?.beatShare) +
    Math.round(competitivenessStats.value?.meetShare) +
    Math.round(competitivenessStats.value?.loseShare) +
    Math.round(competitivenessStats.value?.unavailabilityShare)

  if (sum > 100) {
    return Math.round(competitivenessStats.value?.unavailabilityShare) - 1
  } else if (sum < 100) {
    return Math.round(competitivenessStats.value?.unavailabilityShare) + 1
  } else {
    return Math.round(competitivenessStats.value?.unavailabilityShare)
  }
})
const series = computed(() => {
  const unavailabilityColor =
    Math.round(getUnavailabilityRateValue.value) === 100 ||
    Math.round(getUnavailabilityRateValue.value) > Math.round(competitivenessStats.value?.benchmark)
      ? '#F5B1C1'
      : '#BDE7FF'
  const data = [
    {
      name: t('rc_opportunities_competitive_rate'),
      y: Math.round(competitivenessStats.value?.beatShare),
      color: '#00578B',
    },
    { name: t('rc_opportunities_rate_parity'), y: Math.round(competitivenessStats.value?.meetShare), color: '#0088D9' },
    {
      name: t('rc_opportunities_non_competitive_rate'),
      y: Math.round(competitivenessStats.value?.loseShare),
      color: '#78DAFF',
    },
    {
      name: t('rc_opportunities_rate_unavailability'),
      y: getUnavailabilityRateValue.value,
      color: unavailabilityColor,
    },
  ]

  return data.filter(item => item.y > 0)
})
</script>

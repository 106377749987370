<template>
  <div
    class="tw-flex tw-flex-col tw-w-full tw-pb-4 tw-border-gray-200"
    :class="isLast ? 'tw-border-b-0' : 'tw-border-b'"
  >
    <div class="tw-flex tw-w-row tw-justify-between tw-items-center tw-w-full">
      <div class="tw-flex tw-items-center">
        <p class="tw-text-xl tw-font-bold">{{ $t(group.name) }}</p>
      </div>
      <GhostButton size="medium" :dataTestId="`property-edit-${group.name}`" @click="toggleEditing(group)">{{
        hasFields ? $t('app_hotel_details_edit') : $t('app_hotel_details_add')
      }}</GhostButton>
    </div>
    <div class="tw-w-full tw-flex-row">
      <p v-if="!hasFields">{{ $t(group.teaser) }}</p>
      <ul class="tw-flex tw-flex-wrap">
        <li v-for="field in group.fields" :key="`${field.featureId}`" class="tw-py-2">
          {{ field.name }}
        </li>
      </ul>
      <div class="tw-mt-2">
        <a
          class="tw-text-blue-700 hover:tw-text-blue-800 tw-text-center tw-cursor-pointer tw-transition-colors hover:tw-no-underline tw-duration-300"
          @click="toggleEditing(group)"
          @keydown.enter="toggleEditing(group)"
        >
          {{ $t(hasFields ? 'app_hotel_details_add_more' : 'app_hotel_details_add') }}
        </a>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed } from 'vue'
import GhostButton from '@/components/base/buttons/GhostButton.vue'

const props = defineProps({
  group: {
    type: Object,
    default: () => {},
  },
  isLast: Boolean,
})

const emit = defineEmits(['toggleEditing'])
const toggleEditing = group => emit('toggleEditing', group)

const hasFields = computed(() => props.group.fields !== null)
</script>
<style scoped>
ul li {
  flex: 25% 0 0;
  @media (max-width: theme('screens.md')) {
    flex: 50% 0 0;
  }
  @media (max-width: theme('screens.sm')) {
    flex: 100% 0 0;
  }
}
.dot:hover {
  outline: thick solid theme('colors.decorative.yellow');
  cursor: pointer;
}
</style>

<template>
  <OpportunityAreaLearnMore :isOpen="isLearnMoreOpen" @closeModal="closeLearnMore" />
  <OpportunityAreaChangeCommissionModal
    :isOpen="isChangeCommissionLevelModalOpen"
    :currentCpa="currentCommissionLevel"
    :newCpa="selectedCommissionLevel"
    :prevCpa="prevCommissionLevel"
    :language="language"
    :isLoading="isUpdating || isResumeCampaignLoading"
    :campaignStatus="currentCampaignStatus"
    :hasActivePartner="
      currentRCPartner !== null && currentRCPartner?.partnerStatus === 5 && currentRCPartner.connectionStatus === 5
    "
    :hasMorePartners="selectablePartners.length > 0"
    :currentStep="currentStep"
    @onChange="onCommissionChange"
    @onResume="onCampaignResume"
    @closeModal="closeChangeCommission"
  />
  <div class="tw-pb-6 tw-mb-6 tw-border-b tw-border-gray-300">
    <h1 class="tw-text-black tw-font-bold tw-text-2xl tw-mb-3">
      {{ $t('rc_opportunities_headline') }}
    </h1>

    <p>
      {{ $t('rc_opportunities_subtext') }}
      <button @click="openLearnMore" class="tw-text-blue-700 tw-underline">
        {{ $t('rc_opportunities_learn_more') }}
      </button>
    </p>
    <div class="tw-flex tw-flex-col md:tw-flex-row tw-mt-8 tw-gap-x-8">
      <div class="tw-w-full md:tw-w-1/2 tw-mb-8 md:tw-mb-0">
        <ul class="tw-gap-y-4 tw-flex tw-flex-col">
          <li v-for="item in commissions" :key="item.createdAt">
            <CommissionItem
              :cpa="item.newCpa"
              :variation="item.projectedShareVariation"
              :currentCommissionLevel="item.currentCpa"
              :selectedCommissionLevel="selectedCommissionLevel"
              @onChange="onChange"
              @onSelect="openChangeCommission"
            />
          </li>
        </ul>
      </div>

      <div class="tw-w-full md:tw-w-1/2">
        <CommissionLevelsGraphContainer
          :series="seriesData.data"
          :colors="seriesData.colors"
          :categories="categories"
          :selectedCommissionLevel="selectedCommissionLevel"
          :currentCommissionLevel="currentCommissionLevel"
          :highestPossibleUpgrade="highestPossibleUpgrade"
          @selectLevel="onChange"
        />
      </div>
    </div>
    <div class="tw-flex tw-flex-col md:tw-flex-row tw-mt-3 tw-gap-x-8">
      <div class="tw-w-1/2" />
      <div class="md:tw-w-1/2">
        <p class="tw-text-xs">{{ $t('rc_opportunities_top_position_footnote') }}</p>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'

import CommissionItem from '@/components/rateConnect/opportunityArea/commissionLevel/CommissionItem.vue'
import CommissionLevelsGraphContainer from '@/components/rateConnect/opportunityArea/commissionLevel/CommissionLevelsGraphContainer.vue'
import OpportunityAreaLearnMore from '@/components/rateConnect/opportunityArea/commissionLevel/OpportunityAreaLearnMore.vue'
import OpportunityAreaChangeCommissionModal from '@/components/rateConnect/opportunityArea/commissionLevel/OpportunityAreaChangeCommissionModal.vue'

import useToastNotifications from '@/components/notifications/useToastNotifications'
import appAnalytics from '@/utils/tracking'

import { useExpectedTopShare, useCampaigns, useCampaignsMutation, usePartners } from '@/components/rateConnect/queries'
import { selectedAccommodationId } from '@/layouts/queries'

const store = useStore()
const { displayNotification } = useToastNotifications()
const isLearnMoreOpen = ref(false)
const isChangeCommissionLevelModalOpen = ref(false)
const prevCommissionLevel = ref(null)
const currentStep = ref(1)
const isUpdating = ref(false)

// Check if has active partner
const { partnersData } = usePartners(selectedAccommodationId)
const { currentPartner } = useCampaigns(selectedAccommodationId)
const currentRCPartner = computed(() => {
  const partner = partnersData.value?.find(item => {
    return item.partnerId === currentPartner.value
  })

  return partner ?? null
})
const selectablePartners = computed(() =>
  partnersData.value?.filter(
    item =>
      item.partnerId !== currentPartner.value &&
      item?.partnerStatus === 5 &&
      item.connectionStatus === 5 &&
      item.canNetCpa
  )
)

const { updateCommissionLevel } = useCampaignsMutation()
const language = computed(() => store.state.locale.language)
const { currentCommissionLevel, campaignsData } = useCampaigns(selectedAccommodationId)
const { resumeCampaign, isResumeCampaignLoading } = useCampaignsMutation()
const { expectedTopShare } = useExpectedTopShare(currentCommissionLevel)
const getCommissions = allCommissions => {
  if (!allCommissions) return
  const existingData = [...allCommissions]
  const current = {
    currentCpa: currentCommissionLevel.value,
    newCpa: currentCommissionLevel.value,
    projectedShareVariation: 0,
    createdAt: new Date(),
  }

  existingData?.push(current)
  return existingData?.sort((a, b) => (a.newCpa < b.newCpa ? -1 : b.newCpa < a.newCpa ? 1 : 0))
}

const commissions = computed(() => getCommissions(expectedTopShare.value))
const currentLevel = computed(() => expectedTopShare?.value && expectedTopShare.value[0]?.currentCpa)
const currentCampaignStatus = computed(() => campaignsData.value?.overall?.status)
const selectedCommissionLevel = ref(currentLevel.value)

const highestPossibleUpgrade = computed(() => {
  return expectedTopShare.value?.find(item => item.newCpa === 25)?.projectedShareVariation
})
const openLearnMore = () => {
  isLearnMoreOpen.value = true
}
const closeLearnMore = () => {
  isLearnMoreOpen.value = false
}
const closeChangeCommission = () => {
  currentStep.value = 1
  isChangeCommissionLevelModalOpen.value = false
}
const openChangeCommission = () => {
  appAnalytics.track(appAnalytics.events.RC_OPPORTUNITY_SELECT_COMMISSION, {
    selectedCpa: selectedCommissionLevel.value,
    accommodationId: selectedAccommodationId.value,
  })
  isChangeCommissionLevelModalOpen.value = true
}

const onChange = newCpa => {
  selectedCommissionLevel.value = Number(newCpa)
}

const getFormattedPercentage = item => {
  let newItem = Intl.NumberFormat(language.value, { style: 'percent', minimumFractionDigits: 0 }).format(item / 100)
  if (language.value === 'de-DE' || language.value === 'es-ES' || language.value === 'fr-FE') {
    newItem = newItem.slice(0, -1) + ' %'
  }

  return newItem
}

const categories = computed(() => {
  return commissions.value?.map(item => getFormattedPercentage(item.newCpa))
})

const getBarColor = item => {
  if (item.newCpa === selectedCommissionLevel.value) {
    return '#0088D9'
  } else if (item.newCpa > currentLevel.value) {
    return '#8DE28F'
  } else if (item.newCpa < currentLevel.value) {
    return '#F5B1C1'
  } else if (item.newCpa === currentLevel.value && item.newCpa !== selectedCommissionLevel.value) {
    return '#8DE28F'
  }
}
const seriesData = computed(() => {
  const data = []
  const colors = []
  let numberValue
  commissions.value?.forEach(item => {
    colors.push(getBarColor(item))

    if (item.projectedShareVariation > 0) {
      numberValue = 1 + Math.abs(item.projectedShareVariation) / 100
      data.push(numberValue)
    }
    if (item.projectedShareVariation < 0) {
      numberValue = 1 - Math.abs(item.projectedShareVariation) / 100
      data.push(numberValue)
    }
    if (item.projectedShareVariation === 0) {
      data.push(1)
    }
  })

  return { data: [{ data: data }], colors }
})

const onCommissionChange = () => {
  isUpdating.value = true
  prevCommissionLevel.value = currentLevel.value
  updateCommissionLevel(
    {
      accommodationId: selectedAccommodationId.value,
      commissionLevel: selectedCommissionLevel.value,
    },
    {
      onSuccess: () => {
        isUpdating.value = false
        appAnalytics.track(appAnalytics.events.RC_OPPORTUNITY_CHANGE_COMMISSION, {
          selectedCpa: selectedCommissionLevel.value,
          accommodationId: selectedAccommodationId.value,
        })

        if (currentCampaignStatus.value === 'active') {
          displayNotification({
            message: 'rc_cpa_commission_change_success',
            isTranslationKey: true,
            type: 'success',
          })
          closeChangeCommission()
        } else {
          currentStep.value = 2
        }
      },
      onError: () => {
        isUpdating.value = false
        displayNotification({
          message: 'something_went_wrong',
          isTranslationKey: true,
          type: 'error',
        })
        if (currentCampaignStatus.value === 'active') {
          closeChangeCommission()
        }
      },
    }
  )
}

const onCampaignResume = () => {
  resumeCampaign(
    {
      accommodationId: selectedAccommodationId.value,
    },
    {
      onSuccess: () => {
        appAnalytics.track(appAnalytics.events.RC_OPPORTUNITY_ACTIVATE_CAMPAIGN, {
          accommodationId: selectedAccommodationId.value,
        })
        closeChangeCommission()
        displayNotification({
          titleTranslationKey: 'rc_campaign_resumed_header',
          message: 'rc_campaign_resumed_success',
          isTranslationKey: true,
          type: 'success',
        })
      },
      onError: () => {
        closeChangeCommission()
        displayNotification({
          message: 'save_error',
          isTranslationKey: true,
          type: 'error',
        })
      },
    }
  )
}
</script>

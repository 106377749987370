<template>
  <dialog
    ref="changeCommissionModalRef"
    class="tw-drop-shadow-md tw-z-40 sm:tw-rounded-[20px] tw-m-0 sm:tw-m-auto tw-max-w-full sm:tw-max-w-[revert] tw-max-h-[100dvh] tw-max-h-[100vh] tw-min-h-[100dvh] tw-min-h-[100vh] sm:tw-min-h-[revert] sm:tw-max-h-[revert]"
  >
    <div class="tw-text-right">
      <button @click="onCloseClick">
        <BaseIcon icon-name="cross" :height="24" :width="24" :viewbox-height="24" :viewbox-width="24">
          <Cross />
        </BaseIcon>
      </button>
    </div>
    <CPACampaignSetup
      isChangingCampaign
      :eligibleCommissionLevels="selectableCommissionLevels"
      :selectedCampaignType="currentCampaignType"
      :selectedCommissionLevel="selectedCommissionLevel"
      @onSelectedCommissionChange="onSelectedCommissionChange"
      class="md:tw-px-5"
    />
    <div class="tw-hidden md:tw-block tw-mt-8">
      <hr />
      <div class="tw-px-5 tw-pb-4 tw-pt-8 tw-flex tw-justify-between tw-items-center">
        <GhostButton @click="onCloseClick">{{ $t('rc_campaign_type_change_cancel_button') }}</GhostButton>
        <MainButton @click="onCommissionSaveClick" :disabled="selectedCommissionLevel === currentCommissionLevel">{{
          $t('rc_campaign_type_change_save_button')
        }}</MainButton>
      </div>
    </div>
    <div class="md:tw-hidden tw-flex tw-flex-col tw-gap-2 tw-my-4">
      <MainButton @click="onCommissionSaveClick" :disabled="selectedCommissionLevel === currentCommissionLevel">{{
        $t('rc_campaign_type_change_save_button')
      }}</MainButton>
      <BaseButton @click="onCloseClick" class="tw-text-gray-700 tw-font-bold">{{
        $t('rc_campaign_type_change_cancel_button')
      }}</BaseButton>
    </div>
    <div
      class="tw-fixed tw-h-full tw-bg-white tw-opacity-80 tw-z-50 tw-inset-0"
      v-show="isStartUpFetching || isCampaignsFetching || isUpdatingCommissionLevel"
    >
      <LaunchpadLoader class="tw-h-full tw-flex tw-items-center tw-justify-center" />
    </div>
  </dialog>
</template>

<script setup>
import { ref, computed } from 'vue'

import BaseIcon from '@/components/BaseIcon.vue'
import Cross from '@/components/icons/Cross.vue'
import CPACampaignSetup from '@/components/rateConnect/setup/CPACampaignSetup.vue'
import BaseButton from '@/components/base/buttons/BaseButton.vue'
import GhostButton from '@/components/base/buttons/GhostButton.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'
import LaunchpadLoader from '@/components/dashboard/common/LaunchpadLoader.vue'
import { useStartUp, useCampaigns, useCampaignsMutation } from '@/components/rateConnect/queries'
import useToastNotifications from '@/components/notifications/useToastNotifications'
import { selectedAccommodationId } from '@/layouts/queries'

const { displayNotification } = useToastNotifications()
const { currentCampaignType, hotelierData, isFetching: isStartUpFetching } = useStartUp(selectedAccommodationId)
const { currentCommissionLevel, isFetching: isCampaignsFetching } = useCampaigns(selectedAccommodationId)
const { updateCommissionLevel } = useCampaignsMutation()

const isUpdatingCommissionLevel = ref(false)
const changeCommissionModalRef = ref(null)
const userSelectedCommissionLevel = ref(null)
const selectedCommissionLevel = computed(() => userSelectedCommissionLevel.value ?? currentCommissionLevel.value)
const selectableCommissionLevels = computed(() =>
  currentCampaignType.value === 'cpa'
    ? hotelierData.value?.cpaCommissionLevels?.cpa
    : hotelierData.value?.cpaCommissionLevels?.netCpa
)

const onSelectedCommissionChange = level => (userSelectedCommissionLevel.value = level)

const onCommissionSaveClick = () => {
  isUpdatingCommissionLevel.value = true
  updateCommissionLevel(
    {
      accommodationId: selectedAccommodationId.value,
      commissionLevel: userSelectedCommissionLevel.value,
    },
    {
      onSuccess: () => {
        displayNotification({
          message: 'rc_cpa_commission_change_success',
          isTranslationKey: true,
          type: 'success',
        })
        onCloseClick()
      },
      onError: () => {
        displayNotification({
          message: 'save_error',
          isTranslationKey: true,
          type: 'error',
        })
        onCloseClick()
      },
    }
  )
}

const showModal = () => {
  changeCommissionModalRef.value?.showModal()
}

const onCloseClick = () => {
  isUpdatingCommissionLevel.value = false
  changeCommissionModalRef.value?.close()
}

defineExpose({
  showModal,
})
</script>

<template>
  <div class="tw-flex tw-flex-col tw-h-full">
    <div class="tw-mb-auto">
      <h2 class="tw-font-bold tw-text-xl tw-mb-2">{{ $t('rc_opportunities_top_position_header') }}</h2>
      <p class="tw-text-sm">
        {{
          currentCommissionLevel === 25
            ? $t('rc_opportunities_highest_commission')
            : $t('rc_opportunities_top_position_subtext', {
                Percentage: `${Intl.NumberFormat(language, { style: 'percent', minimumFractionDigits: 0 }).format(highestPossibleUpgrade / 100)}`,
              })
        }}
      </p>
    </div>
    <div>
      <CommissionLevelsGraph
        class="tw-mt-6"
        :class="categories.length === 6 ? 'tw-h-[500px]' : 'tw-h-[390px]'"
        :series="series"
        :categories="categories"
        :colors="colors"
        @selectLevel="selectLevel"
      />
    </div>
  </div>
</template>
<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

import CommissionLevelsGraph from '@/components/rateConnect/opportunityArea/commissionLevel/CommissionLevelsGraph.vue'

const store = useStore()

const emit = defineEmits(['selectLevel'])
const selectLevel = level => emit('selectLevel', level)

defineProps({
  selectedCommissionLevel: Number,
  currentCommissionLevel: Number,
  highestPossibleUpgrade: Number,
  series: Object,
  categories: {
    type: Array,
    default: () => [],
  },
  colors: {
    type: Array,
    default: () => [],
  },
})

const language = computed(() => store.state.locale.language)
</script>

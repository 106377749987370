<template>
  <CustomModal
    class="export-data-modal"
    :is-modal-open="isOpen"
    @toggleModal="emit('closeModal')"
    @onClickAway="emit('closeModal')"
    :overlay="'dark'"
    :with-header="true"
    :with-footer="true"
    custom-classes="tw-h-screen md:tw-h-auto tw-w-[100vw] md:tw-max-w-[636px] md:tw-w-[636px] md:tw-rounded-md tw-rounded-none"
  >
    <template #modalHeader
      ><p class="tw-text-xl tw-max-w-[90%]">{{ $t(title) }}</p></template
    >

    <div class="tw-flex tw-flex-col tw-w-full">
      <div v-if="currentStep === 1 && hasActivePartner">
        <p class="tw-pb-6" v-html="$t(subtitle)" />
        <div class="tw-flex tw-items-center tw-justify-center tw-pb-6">
          <i18n-t keypath="rc_opportunities_commission_change" tag="p" scope="global">
            <template #FromPercentage>
              <span class="tw-font-bold tw-text-[32px] tw-mx-5">
                {{
                  Intl.NumberFormat(language, { style: 'percent', minimumFractionDigits: 0 }).format(currentCpa / 100)
                }}</span
              >
            </template>
            <template #ToPercentage>
              <span class="tw-font-bold tw-text-[32px] tw-mx-5" :class="{ 'tw-text-red-700': currentCpa > newCpa }">
                {{
                  Intl.NumberFormat(language, { style: 'percent', minimumFractionDigits: 0 }).format(newCpa / 100)
                }}</span
              >
            </template>
          </i18n-t>
        </div>
        <div v-if="currentCpa < newCpa">
          <p class="tw-pb-6" v-html="$t('rc_opportunities_commission_change_info')" />
          <ul class="tw-list-disc tw-ml-6">
            <li v-for="item in upgradePoints" :key="item">{{ $t(item) }}</li>
          </ul>
        </div>
        <div v-else>
          <p class="tw-pb-6" v-html="$t('rc_opportunities_commission_decrease_info')" />
          <ul class="tw-list-disc tw-ml-6 tw-pb-6">
            <li v-for="item in downgradePoints" :key="item">{{ $t(item) }}</li>
          </ul>
          <p>{{ $t('rc_opportunities_commission_decrease_confirm') }}</p>
        </div>
      </div>

      <div v-if="currentStep === 1 && !hasActivePartner">
        <div v-if="hasMorePartners">
          <p class="tw-pb-6">{{ $t('rc_opportunities_tech_issue') }}</p>
          <p class="tw-pb-6" v-html="$t('rc_opportunities_tech_issue_support')" />
          <p>{{ $t('rc_opportunities_tech_issue_info') }}</p>
        </div>
        <div v-else>
          <p class="tw-pb-6">{{ $t('rc_opportunities_campaign_offline_info') }}</p>
          <p v-html="$t('rc_opportunities_campaign_offline_support')" />
        </div>
      </div>

      <div v-if="currentStep === 2 && campaignStatus !== 'active'">
        <div class="tw-bg-green-100 tw-text-green-800 tw-p-4 tw-rounded-xl shadow" v-if="currentCpa !== prevCpa">
          <i18n-t keypath="rc_opportunities_commission_changed_success" tag="p" scope="global">
            <template #FromPercentage>
              <strong>{{
                Intl.NumberFormat(language, { style: 'percent', minimumFractionDigits: 0 }).format(prevCpa / 100)
              }}</strong>
            </template>
            <template #ToPercentage>
              <strong>{{
                Intl.NumberFormat(language, { style: 'percent', minimumFractionDigits: 0 }).format(currentCpa / 100)
              }}</strong>
            </template>
          </i18n-t>
        </div>
        <div v-else class="tw-w-full tw-flex tw-justify-center tw-items-center">
          <RCInlineLoader color="#8D8D8B" />
        </div>
        <p class="tw-py-6" v-html="$t('rc_opportunities_campaign_paused_notification')" />
        <p>{{ $t('rc_opportunities_campaign_paused_info') }}</p>
      </div>
    </div>

    <template #modalFooter>
      <div
        class="tw-flex tw-w-full tw-items-start md:tw-items-center md:tw-flex-row tw-flex-col"
        :class="hasSteps ? 'tw-justify-between' : 'tw-justify-end'"
      >
        <div v-if="hasSteps" class="md:tw-w-1/2 tw-ml-1 md:tw-ml-0">
          <p class="tw-text-sm" v-if="currentStep === 1" v-html="$t('rc_opportunities_step_1')" />
          <p class="tw-text-sm" v-if="currentStep === 2" v-html="$t('rc_opportunities_step_2')" />
        </div>
        <div class="tw-flex tw-w-full tw-justify-between md:tw-justify-end tw-gap-x-4 tw-mt-4 md:tw-mt-0">
          <GhostButton class="tw-w-full md:tw-w-auto" @click="ghostButtonAction">{{
            $t(ghostButtonLabel)
          }}</GhostButton>
          <MainButton class="tw-w-full md:tw-w-auto" @click="mainButtonAction" :disabled="isLoading">
            <span v-show="!isLoading" class="tw-text-nowrap">{{ $t(mainButtonLabel) }}</span>
            <span v-show="isLoading"> <RCInlineLoader color="#fff" /></span>
          </MainButton>
        </div>
      </div>
    </template>
  </CustomModal>
</template>

<script setup>
import { computed } from 'vue'
import { useRouter } from 'vue-router'

import CustomModal from '@/components/base/CustomModal.vue'
import MainButton from '@/components/base/buttons/MainButton.vue'
import GhostButton from '@/components/base/buttons/GhostButton.vue'
import RCInlineLoader from '@/components/rateConnect/RCInlineLoader.vue'

import { selectedAccommodationId } from '@/layouts/queries'
import { getSupportCenterUrl } from '@/utils/Utility'

import appAnalytics from '@/utils/tracking'

const router = useRouter()
const emit = defineEmits(['closeModal', 'onChange', 'onResume'])

const props = defineProps({
  isOpen: Boolean,
  currentCpa: Number,
  newCpa: Number,
  prevCpa: Number,
  language: String,
  currentStep: Number,
  isLoading: Boolean,
  hasActivePartner: Boolean,
  campaignStatus: String,
  hasMorePartners: Boolean,
})

const supportCenterUrl = computed(() => getSupportCenterUrl(props.language, 'contactUs'))
const upgradePoints = [
  'rc_opportunities_commission_benefit_1',
  'rc_opportunities_commission_benefit_2',
  'rc_opportunities_commission_benefit_3',
]
const downgradePoints = [
  'rc_opportunities_commssion_decrease_info_1',
  'rc_opportunities_commission_decrease_info_2',
  'rc_opportunities_commission_decrease_info_3',
]
const title = computed(() => {
  if (props.currentStep === 2) {
    return props.prevCpa > props.currentCpa
      ? 'rc_opportunities_commission_decrease_resume'
      : 'rc_opportunities_commission_increase_resume'
  } else {
    if (props.currentCpa < props.newCpa) {
      // Commission level is upgraded
      if (props.hasActivePartner && props.campaignStatus === 'active') {
        return 'rc_opportunities_commission_increase_header'
      } else if (props.hasActivePartner && props.campaignStatus === 'inactive') {
        return 'rc_opportunities_commission_increase_resume'
      } else if (!props.hasActivePartner) {
        return 'rc_opportunities_campaign_offline'
      } else {
        return ''
      }
    } else if (props.currentCpa > props.newCpa) {
      // Commission level is downgrade
      if (props.hasActivePartner && props.campaignStatus === 'active') {
        return 'rc_opportunities_commission_decrease_header'
      } else if (props.hasActivePartner && props.campaignStatus !== 'active') {
        return 'rc_opportunities_commission_decrease_resume'
      } else if (!props.hasActivePartner) {
        return 'rc_opportunities_campaign_offline'
      } else {
        return ''
      }
    } else {
      return ''
    }
  }
})

const subtitle = computed(() => {
  if (props.currentCpa < props.newCpa) return 'rc_opportunities_commission_increase_subtext'
  else return 'rc_opportunities_commission_decrease_subtext'
})

const mainButtonLabel = computed(() => {
  if (!props.hasActivePartner && props.hasMorePartners) return 'rc_opportunities_cta_settings'
  if (!props.hasActivePartner && !props.hasMorePartners) return 'rc_opportunities_contact_cta'
  if (props.currentStep === 2) return 'rc_reactivate_campaign_button'
  return 'rc_opportunities_cta_confirm'
})

const ghostButtonLabel = computed(() => {
  if (!props.hasActivePartner && props.hasMorePartners) return 'rc_opportunities_contact_cta'
  return 'rc_opportunities_cta_close'
})

const mainButtonAction = () => {
  if (!props.hasActivePartner && props.hasMorePartners) {
    appAnalytics.track(appAnalytics.events.RC_OPPORTUNITY_SETTINGS_REDIRECTION, {
      selectedCpa: props.newCpa,
      accommodationId: selectedAccommodationId.value,
    })
    router.push({ name: 'rate-connect-running-settings' })
  } else if (!props.hasActivePartner && !props.hasMorePartners) {
    appAnalytics.track(appAnalytics.events.RC_OPPORTUNITY_CONTACT_US_REDIRECTION, {
      selectedCpa: props.newCpa,
      accommodationId: selectedAccommodationId.value,
    })
    window.open(supportCenterUrl.value, '_blank')
  } else if (props.currentStep === 1 && props.campaignStatus === 'inactive') {
    emit('onChange')
  } else if (props.currentStep === 2) {
    emit('onResume')
  } else {
    emit('onChange')
  }
}

const ghostButtonAction = () => {
  if (!props.hasActivePartner && props.hasMorePartners) {
    appAnalytics.track(appAnalytics.events.RC_OPPORTUNITY_CONTACT_US_REDIRECTION, {
      selectedCpa: props.newCpa,
      accommodationId: selectedAccommodationId.value,
    })
    window.open(supportCenterUrl.value, '_blank')
  }
  emit('closeModal')
}

const hasSteps = computed(() => props.campaignStatus === 'inactive' && props.hasActivePartner)
</script>
<style scoped>
.shadow {
  box-shadow: 0px 4px 16px 0px #00000026;
}
</style>

<template>
  <div class="tw-bg-gray-100 tw-flex tw-flex-col tw-p-4 tw-rounded-2xl">
    <div class="tw-flex tw-flex-row tw-justify-between tw-pb-4 tw-border-b tw-border-gray-300">
      <div class="tw-flex tw-items-center">
        <h3 class="tw-font-bold tw-mr-1">{{ $t(label) }}</h3>
        <UiTooltip :label="$t(tooltip)" tooltip-position="top" theme="customdarkwithpadding">
          <BaseIcon
            icon-name="information-outline"
            :height="16"
            :width="16"
            class="tw-fill-none tw-stroke-gray-500 tw-h-[19px] tw-w-[19px]"
          >
            <InformationOutline />
          </BaseIcon>
        </UiTooltip>
      </div>
      <p
        class="tw-text-[24px] tw-leading-[30px] sm:tw-text-3.5xl"
        :class="{ 'tw-text-red-700': Math.round(value) === 100 || Math.round(value) > Math.round(benchmark) }"
      >
        {{ unavailability }}
      </p>
    </div>
    <div class="tw-flex tw-flex-row tw-justify-between tw-pt-4 tw-mb-4">
      <div class="tw-flex tw-items-center">
        <h3 class="tw-font-bold tw-mr-1">{{ $t('rc_opportunities_benchmark') }}</h3>
        <UiTooltip
          :label="$t('rc_opportunities_benchmark_tooltip')"
          tooltip-position="top"
          theme="customdarkwithpadding"
        >
          <BaseIcon
            icon-name="information-outline"
            :height="16"
            :width="16"
            class="tw-fill-none tw-stroke-gray-500 tw-h-[19px] tw-w-[19px]"
          >
            <InformationOutline />
          </BaseIcon>
        </UiTooltip>
      </div>
      <p class="tw-text-[24px] tw-leading-[30px] sm:tw-text-3.5xl">
        {{ Intl.NumberFormat(language, { style: 'percent', minimumFractionDigits: 0 }).format(benchmark / 100) }}
      </p>
    </div>
    <div
      class="tw-px-5 tw-py-4 tw-rounded-2xl"
      :class="Math.round(value) === 100 ? 'tw-bg-red-200 tw-text-red-800' : 'tw-bg-blue-200 tw-text-blue-800'"
    >
      <h2 class="tw-font-bold tw-mb-2">
        {{
          Math.round(value) === 100
            ? $t('rc_opportunities_unavailability_tip_header')
            : $t('rc_opportunities_visibility_tip_header')
        }}
      </h2>
      <p class="tw-text-sm">
        {{
          Math.round(value) === 100
            ? $t('rc_opportunities_visibility_tip_subtext')
            : $t('rc_opportunities_unavailability_tip_subtext')
        }}
      </p>

      <ul class="tw-list-disc tw-ml-4 tw-text-sm" v-if="Math.round(value) < 100">
        <li v-html="$t('rc_opportunities_visibility_tip_1')" />
        <li>{{ $t('rc_opportunities_visibility_tip_2', { ConnectivityProvider: partnerName }) }}</li>
        <li v-html="$t('rc_opportunities_visibility_tip_3')" />
      </ul>
      <ul class="tw-list-disc tw-ml-4 tw-text-sm tw-mb-6" v-else>
        <li v-html="$t('rc_opportunities_unavailability_tip_1')" />
        <li>{{ $t('rc_opportunities_unavailability_tip_2', { ConnectivityProvider: partnerName }) }}</li>
        <li>{{ $t('rc_opportunities_unavailability_tip_3', { ConnectivityProvider: partnerName }) }}</li>
        <li>{{ $t('rc_opportunities_unavailability_tip_4', { ConnectivityProvider: partnerName }) }}</li>
        <li v-html="$t('rc_opportunities_unavailability_tip_5')" />
      </ul>
      <p class="tw-inline-block" v-if="unavailability === 100">
        <span class="tw-mr-1 tw-text-sm" v-html="$t('rc_opportunities_unavailability_nba_header')" />
        <i18n-t keypath="rc_opportunities_unavailabiltiy_nba" tag="span" class="tw-text-sm" scope="global">
          <template #Settings>
            <router-link
              v-bind="$attrs"
              :to="{ name: 'rate-connect-running-settings' }"
              class="tw-underline hover:tw-text-blue-700"
            >
              {{ $t('rc_opportunities_nba_settings') }}
            </router-link>
          </template>
        </i18n-t>
      </p>
    </div>
  </div>
</template>
<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

import UiTooltip from '@/components/dashboard/ui/UiTooltip.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import InformationOutline from '@/components/icons/InformationOutline.vue'

const store = useStore()
const props = defineProps({
  label: String,
  tooltip: String,
  value: Number,
  benchmark: Number,
  partnerName: String,
})

const language = computed(() => store.state.locale.language)
const unavailability = computed(() =>
  Intl.NumberFormat(language.value, { style: 'percent', minimumFractionDigits: 0 }).format(props.value / 100)
)
</script>

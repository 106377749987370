class AppAnalytics {
  constructor() {
    this.queue = []
    this.events = {
      BUTTON_CLICKED: 'button_clicked',
      USER_LOGGED_IN_FROM_LOGIN_AS_ADMIN_URL: 'user_logged_in_from_login_as_admin_url',
      // Free Links
      FREE_LINKS_ACTIVATION_ERROR: 'free_links_activation_error',
      FREE_LINKS_ACTIVATION_SUCCESS: 'free_links_activation_success',
      // Rate Connect
      RC_ONBOARDING_PAYMENT_SUCCESS: 'rc_onboarding_payment_success',
      RC_ONBOARDING_PAYMENT_FAILURE: 'rc_onboarding_payment_failure',
      RC_ONBOARDING_STARTED: 'rc_onboarding_started',
      RC_PROVIDER_CHANGE_SUCCESS: 'rc_provider_change_success',
      RC_PROVIDER_CHANGE_CAMPAIGN_CHANGE_SUCCESS: 'rc_provider_change_campaign_change_success',
      RC_PROVIDER_CHANGE_FAILED: 'rc_provider_change_failed',
      RC_PROVIDER_CHANGE_CAMPAIGN_CHANGE_FAILED: 'rc_provider_change_campaign_change_failed',
      RC_CAMPAIGN_RESUMED: 'tRC_campaign_resumed',
      RC_PAUSE_CAMPAIGN_BUTTON_CLICKED_SETTINGS: 'rc_pause_campaign_button_clicked_settings',
      RC_PAUSE_CAMPAIGN_REASON_SELECTED: 'rc_pause_campaign_reason_selected',
      RC_PAUSE_CAMPAIGN_CONTINUE_BUTTON_CLICKED: 'rc_pause_campaign_continue_button_clicked',
      RC_PAUSE_CAMPAIGN_2_KEEP_RATES_LIVE: 'rc_pause_campaign_2_keep_rates_live',
      RC_PAUSE_CAMPAIGN_CHANGE_CAMPAIGN_TYPE: 'rc_pause_campaign_change_campaign_type',
      RC_PAUSE_CAMPAIGN_IN_FLOW_BUTTON_CLICKED: 'rc_pause_campaign_in_flow_button_clicked',
      RC_PAUSE_CAMPAIGN_3_KEEP_RATES_LIVE: 'rc_pause_campaign_3_keep_rates_live',
      RC_PAUSE_CAMPAIGN_SEND_FEEDBACK: 'rc_pause_campaign_send_feedback',
      RC_PAUSE_CAMPAIGN_NO_FEEDBACK: 'rc_pause_campaign_no_feedback',
      RC_PAUSE_CAMPAIGN_TYPE_CHANGE_SUCCESS: 'rc_pause_campaign_type_change_success',
      RC_OPPORTUNITY_PLUS_REDIRECTION: 'rc_opportunity_plus_redirection',
      RC_OPPORTUNITY_SELECT_COMMISSION: 'rc_opportunity_select_commission',
      RC_OPPORTUNITY_CHANGE_COMMISSION: 'rc_opportunity_change_commission',
      RC_OPPORTUNITY_CONTACT_US_REDIRECTION: 'rc_opportunity_contact_us_redirection',
      RC_OPPORTUNITY_SETTINGS_REDIRECTION: 'rc_opportunity_settings_redirection',
      RC_OPPORTUNITY_ACTIVATE_CAMPAIGN: 'rc_opportunity_activate_campaign',
      RC_LOGIN_AS_ADMIN_CLICKED: 'rc_login_as_admin_clicked',

      // Studio Plus
      SP_HOMEPAGE_ONBOARDING_VISITED: 'sp_onboarding_visited',
      SP_HOMEPAGE_BANNER_CLICKED: 'sp_homepage_banner_clicked',
      SP_COMPARE_FEATURES_VISITED: 'sp_compare_features_visited',
      SP_VISIT_RI_FROM_BANNER: 'sp_visit_ri_from_banner',
      SP_CLICK_UPGRADE_BANNER: 'sp_click_upgrade_banner',
      SP_CLICK_UPGRADE_PAIN_POINTS: 'sp_click_upgrade_pain_points',
      SP_CLICK_RI_TILE: 'sp_click_ri_tile',
      SP_CLICK_VP_TILE: 'sp_click_vp_tile',
      SP_CLICK_HC_TILE: 'sp_click_hc_tile',
      SP_CLICK_RI_VIDEO: 'sp_click_ri_video',
      SP_CLICK_VP_VIDEO: 'sp_click_vp_video',
      SP_CLICK_HC_VIDEO: 'sp_click_hc_video',
      SP_UPGRADE_FEATURE_COMPARISON: 'sp_upgrade_feature_comparison',
      SP_PAYMENT_VISITED: 'sp_payment_visited',
      SP_SELECT_CURRENCY_PAYMENT_PAGE: 'sp_select_currency_payment_page',
      SP_START_PLUS_SUBSCRIPTION_BUTTON_CLICKED: 'sp_start_plus_subscription_button_clicked',
      SP_PAYMENT_SUCCEED: 'sp_payment_succeed',
      SP_PAYMENT_FAILED: 'sp_payment_failed',
      SP_SUBSCRIPTION_CANCELED: 'sp_subscription_canceled',
      SP_SUBSCRIPTION_REACTIVATED: 'sp_subscription_reactivated',
      SP_BOOK_APPOINTMENT: 'sp_book_appointment',
      SP_KEEP_BENEFITS: 'sp_keep_benefits',
      SP_CONTINUE_CANCELLATION: 'sp_continue_cancellation',
      SP_KEEP_SUBSCRIPTION: 'sp_keep_subscription',
      SP_CANCEL_SUBSCRIPTION: 'sp_cancel_subscription',
      SP_REACTIVATION_CONTACT_US: 'sp_reactivation_contact_us',
      SP_REACTIVATION_KEEP_BENEFITS: 'sp_reactivation_keep_benefits',
      SP_REACTIVATION_BUTTON_HP: 'sp_reactivation_button_hp',
      SP_REACTIVATE_BTN: 'sp_reactivate_btn',
      SP_REACTIVATION_CANCEL_BTN: 'sp_reactivation_cancel_btn',
      SP_ADD_VOUCHER_LINK: 'sp_add_voucher_link',
      SP_APPLY_VOUCHER: 'sp_apply_voucher',
      SP_REMOVE_VOUCHER: 'sp_remove_voucher',
      SP_LOGIN_AS_ADMIN_CLICKED: 'sp_login_as_admin_clicked',
      // Rate Insights
      RI_NO_BOOKING_SITES: 'ri_no_booking_sites',
      RI_NO_DATA_FOR_FILTERS: 'ri_no_data_for_filters',
      // Visitors Profile
      VP_TIMEFRAME_CHANGED: 'vp_timeframe_changed',
      // Studio Onboarding
      ONBOARDING_PERSONAL_INFORMATION_FORM: 'onboarding_personal_information_form',
      ONBOARDING_ASSIGN_ACCOMMODATION: 'onboarding_assign_accommodation',
      ONBOARDING_CANNOT_FIND_PROPERTY: 'onboarding_cannot_find_property',
      ONBOARDING_ASSIGNED_ITEM_STATE: 'onboarding_assigned_item_state',
      ONBOARDING_PROPERTY_ASSIGNED_LINK: 'onboarding_property_assigned_link',
    }
    this.readTimeout = null
    this.readRetryCount = 0
  }

  identify(id = '', properties = {}) {
    if (!id) return

    this.addToQueue('identify', {
      event: id,
      properties: properties,
    })

    this.readQueue()
  }

  track(event = '', properties = {}) {
    if (!event) return

    this.addToQueue('track', {
      event,
      properties,
    })
    this.readQueue()
  }

  reset() {
    if (window.analytics) {
      window.analytics.reset()
    }
  }

  page(properties) {
    this.addToQueue('page', {
      properties,
    })
    this.readQueue()
  }

  addToQueue(type, item) {
    this.queue.push({
      type: type,
      item: item,
    })
  }

  readQueue() {
    clearTimeout(this.readTimeout)
    if (this.queue.length === 0 || this.readRetryCount >= 240) return

    if (!window.analytics) {
      // make sure to try again and not wait for the next
      // event to be tracked before reading the queue
      this.readTimeout = setTimeout(() => {
        this.readRetryCount += 1
        this.readQueue()
      }, 500)

      return
    }

    let index = 0
    while (this.queue.length > 0) {
      const data = this.queue[index]
      const type = data.type
      const item = data.item

      if (!item) {
        window.analytics[type]()
      } else {
        window.analytics[type](item.event, item.properties)
      }

      this.queue.splice(index, 1)
    }
  }
}

export default new AppAnalytics()

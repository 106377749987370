<template>
  <div class="sm:tw-mx-4 lg:tw-mx-0">
    <div v-if="initialDataLoading" class="tw-rounded-xl tw-animate-pulse tw-bg-gray-300 tw-h-[658px]"></div>
    <div v-else-if="requiredDataHasErrors">
      <DataErrorView />
    </div>

    <PerformanceStatsWrapper v-else :markets="marketsData" :currentCampaignType="currentCampaignType" />
    <FullScreenLoader v-show="requiredDataIsBeingRefetched" />
  </div>
</template>
<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'

import PerformanceStatsWrapper from '@/components/rateConnect/performance/PerformanceStatsWrapper.vue'
import FullScreenLoader from '@/components/base/loaders/FullScreenLoader.vue'
import { useCampaigns, useMarkets, usePartners, useStartUp } from '@/components/rateConnect/queries'
import { selectedAccommodationId } from '@/layouts/queries'
import DataErrorView from '@/components/rateConnect/performance/DataErrorView.vue'

const store = useStore()
const language = computed(() => store.state.locale?.language)
const { currentPartner } = useCampaigns(selectedAccommodationId)
const {
  isFetching: isStartUpFetching,
  isLoading: isStartUpLoading,
  isError: isStartUpError,
  currentCampaignType,
} = useStartUp(selectedAccommodationId)
const {
  isFetching: isPartnersFetching,
  isLoading: isPartnersLoading,
  isError: isPartnersError,
} = usePartners(selectedAccommodationId)
const {
  isFetching: isMarketsFetching,
  isInitialLoading: isMarketsLoading,
  isError: isMarketsError,
  marketsData,
} = useMarkets(selectedAccommodationId, language, currentPartner)

const initialDataLoading = computed(() => isPartnersLoading.value || isMarketsLoading.value || isStartUpLoading.value)

const requiredDataHasErrors = computed(() => isPartnersError.value || isMarketsError.value || isStartUpError.value)

const requiredDataIsBeingRefetched = computed(
  () => !initialDataLoading.value && (isPartnersFetching.value || isMarketsFetching.value || isStartUpFetching.value)
)
</script>
